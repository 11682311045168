import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import { article } from '../styles/layout.css';
import { a, h2, p } from '../styles/global.css';
import { Link } from 'gatsby';

const IndexPage = () => {

  return (
    <Layout pageTitle="Home">
      <article className={article}>
        <h2 className={h2}>Hi, Ben Foden here.</h2>
        <p className={p}>
          I do design, <code>code</code>, and branding. </p>
        <p className={p}>
          <Link to="/contact" className={a}>Say hi!</Link></p>
        {/* <StaticImage src="../images/IMG_E4702.JPG" alt="green maple leaves" placeholder="blurred" width={960} height={960} /> */}
      </article>
    </Layout>
  );
}
export default IndexPage;
